import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { SPLIT_PRICE } from 'src/templates/CalendarProductPage/constants'
import { Bnplsplit, ShopPayIcon, ShopPayContainer, ToolTipContainer } from './styles'

const ShopPay = ({ onTooltipClick, size, style, isCart }) => {
  const { shopPay, tooltipicon } = useStaticQuery(graphql`
    query ShopPayQuery {
      shopPay: file(relativePath: { eq: "calmax/shop-pay.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      tooltipicon: file(relativePath: { eq: "tool-tip.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <>
      <Bnplsplit isCart={isCart}>
        or 4 interest-free payments of{' '}
        <b>${SPLIT_PRICE[size]?.[style] || SPLIT_PRICE[size]?.default}</b> with
      </Bnplsplit>
      <ShopPayContainer onClick={onTooltipClick} marginBottom={isCart}>
        <ShopPayIcon>
          <GatsbyImage image={shopPay.childImageSharp.gatsbyImageData} alt="Shop Pay Logo" />
        </ShopPayIcon>
        <ToolTipContainer>
          <GatsbyImage
            image={tooltipicon.childImageSharp.gatsbyImageData}
            alt="Tool Tip for More Information"
          />
        </ToolTipContainer>
      </ShopPayContainer>
    </>
  )
}

export default ShopPay

ShopPay.propTypes = {
  onTooltipClick: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.string.isRequired,
  isCart: PropTypes.bool,
}
