import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Section = styled.div`
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #e5f0f0 0%, #fffef8 88.07%);
  @media (max-width: ${breakpoints.xl}px) {
    padding: 30px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 0px;
  }
`
export const ChoreCountContainer = styled.div`
  filter: drop-shadow(0px 12px 40px rgba(0, 0, 0, 0.12));
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: white;
  padding: 30px;
  width: 350px;
  margin: auto;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 32px;
    width: 350px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 340px;
    padding: 20px 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 24px auto;
    padding: 12px 27px;
    width: 220px;
    border-radius: 8px;
  }
  @media (max-width: 400px) {
    width: 210px;
  }
`
export const Count = styled.span`
  display: inline-block;
  color: black;
  font-family: 'filsonPro';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 30px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
    margin-left: 20px;
  }
  @media (max-width: 400px) {
    margin-left: 10px;
  }
`

export const Text = styled.p`
  color: black;
  font-family: 'FilsonPro';
  font-size: 24px;
  font-weight: 500;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
export const Header = styled.p`
  color: black;
  font-family: 'filsonPro';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    width: 230px;
    margin: auto;
  }
`
export const Container = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
`
export const ImageContainer = styled.object`
  width: 40px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 20px;
  }
`
export const CalendarSyncsContainer = styled.div`
  position: relative;
  top: -95px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: -150px;

  @media (max-width: ${breakpoints.xl}px) {
    top: -50px;
  }
  @media (max-width: ${breakpoints.l}px) {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    margin-bottom: 30px;
  }
`
export const SubTitle = styled.p`
  color: black;
  text-align: center;
  text-shadow: 0px 12px 40px rgba(0, 0, 0, 0.12);
  font-family: 'FilsonPro';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.48px;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.192px;
    margin-top: 0px;
  }
`
export const Card = styled.div`
  margin: 0px 25px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px 10px;
  }
`
export const IconText = styled.p`
  color: black;
  text-align: center;
  font-family: 'FilsonProBook' !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.216px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.144px;
  }
`
export const Icon = styled(GatsbyImage)`
  width: 80px;
  margin: auto;
  @media (max-width: ${breakpoints.s}px) {
    width: 47px;
  }
`
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
