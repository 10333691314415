import styled, { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { breakpoints, GridLeft, GridRight, TwoColumnGrid } from 'src/utils/styles/global-styles'
import { ContentContainer } from '../../components/ReviewsSection/styles'

export const ShopifyDescription = styled.div`
  margin: 30px 0px;
`
export const ThumbnailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.start ? 'start' : 'center')};
  margin: 10px auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: thin;
  position: relative;
  max-width: 80%;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px 2px 3px;
    margin: 10px;
  }
`

export const ProductImage = styled(GatsbyImage)`
  transform: translateZ(0);
  img {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    width: 100%;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin-right: 5%;
  }
`

export const Thumbnail = styled(GatsbyImage)`
  transform: translateZ(0);
  width: 100px;
  margin: 8px;
  border-radius: 10px;
  @media (max-width: ${breakpoints.s}px) {
    width: 70px;
    border-radius: 7px;
    margin: 8px 4px;
  }
  @media (max-width: 400px) {
    width: 60px;
  }
`
export const ThumbnailButton = styled.button`
  cursor: pointer;
  background-color: white;
  border: none;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0px 7px;
  padding: 0px;
  display: inline-block;
  @media (max-width: 500px) {
    margin: 0px 2px;
  }
`

export const Section = styled.div`
  background-color: ${(props) => props.theme.cream};
  padding-top: 50px;
  @media (max-width: ${breakpoints.l}px) {
    padding-top: 0px;
  }
`
export const Container = styled(ContentContainer)`
  margin-top: 0px;
  max-width: none;
  @media (max-width: ${breakpoints.l}px) {
    margin-top: 0px;
  }
`

export const ProductGridRight = styled(GridRight)`
  p {
    font-family: 'FilsonProBook';
  }
  strong {
    font-family: 'FilsonProBold';
  }
  padding: 0% 5% 0% 5%;
  @media (max-width: ${breakpoints.l}px) {
    padding: 0% 15% 0% 15%;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 0% 5% 0%;
  }
`
export const ProductGridLeft = styled(GridLeft)`
  transform: translateZ(0);
  grid-column: 1 / 3;
  position: relative;
`

export const Grid = styled(TwoColumnGrid)`
  position: relative;
  z-index: 12;
`

export const GiftModeImage = styled.div`
  width: 175px;
  position: absolute;
  left: 20px;
  top: 20px;
  @media (max-width: 1500px) {
    width: 23%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 120px;
    left: 10px;
    top: 10px;
  }
  @media (max-width: 400px) {
    width: 90px;
  }
`
export const CalendarBfBadge = styled.div`
  width: 180px;
  position: absolute;
  right: 50px;
  top: 0px;
  @media (max-width: 1500px) {
    width: 150px;
    right: 30px;
  }
  @media (max-width: ${breakpoints.l}px) {
    right: 120px;
    width: 180px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 120px;
    right: 80px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100px;
    right: 60px;
  }
  @media (max-width: 450px) {
    top: 1px;
    width: 80px;
    right: 50px;
  }
`
export const WaitlistForm = styled.div`
  display: ${(props) => (props.showWaitlist ? 'block' : 'none')};
`
const ArrowStyles = css`
  font-size: 40px;
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  :focus {
    outline: none !important;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 32px;
  }
`

export const LeftArrow = styled.button`
  ${ArrowStyles}
  margin-left: 25px;
  @media (max-width: ${breakpoints.s}px) {
    margin-right: 8px;
    margin-left: 16px;
  }
`

export const RightArrow = styled.button`
  ${ArrowStyles}
  margin-right: 25px;
  @media (max-width: ${breakpoints.s}px) {
    margin-left: 8px;
    margin-right: 16px;
  }
`
export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
