import React from 'react'
import PropTypes from 'prop-types'
import { OuterCircle, Circle, DiagonalLine, Bogo } from './styles'

const Radio = ({ color, oos, selected, bogo, smallRadio, mediumRadio }) => (
  <OuterCircle
    bogo={bogo}
    className={`${color}-outer-${oos}-${selected}`}
    selected={selected}
    smallRadio={smallRadio}
    mediumRadio={mediumRadio}
  >
    {bogo && (
      <Bogo selected={selected}>
        <span>BOGO</span>
      </Bogo>
    )}
    <Circle
      className={`${color}`}
      color={color}
      oos={oos}
      selected={selected}
      smallRadio={smallRadio}
      mediumRadio={mediumRadio}
    />
    {oos && <DiagonalLine />}
  </OuterCircle>
)

export default Radio

Radio.propTypes = {
  color: PropTypes.string,
  oos: PropTypes.bool,
  selected: PropTypes.bool,
  bogo: PropTypes.bool,
  smallRadio: PropTypes.bool,
  mediumRadio: PropTypes.bool,
}
