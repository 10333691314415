import styled from 'styled-components'
import { OneTimePurchase } from 'src/templates/CalendarProductPage/styles'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Bnplsplit = styled(OneTimePurchase)`
  max-width: 100%;
  margin-top: 4px;
  display: ${(props) => (props.isCart ? 'none' : 'block')};
  b {
    font-family: 'FilsonProBold';
  }
`
export const ShopPayIcon = styled.div`
  width: 80px;
`
export const ShopPayContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin: ${(props) => (props.marginBottom ? '2px 0px 16px' : '2px 0px 0px')};
  cursor: pointer;
  @media (max-width: ${breakpoints.m}px) {
    margin: ${(props) => (props.marginBottom ? '2px auto 24px' : '2px 0px 0px')};
  }
`
export const ToolTipContainer = styled.div`
  width: 16px;
  margin: 10px 0px 0px 4px;
`
