import React, { useContext, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { currencyCode } from 'src/utils/constants'
import StoreContext from 'src/context/StoreContext'
import PropTypes from 'prop-types'
import Analytics from 'src/utils/Analytics'
import { SPLIT_PRICE } from 'src/templates/CalendarProductPage/constants'
import { CloseButton, PopupOverlay } from '../../Popup/styles'
import { BNPL_FEATURES } from './constants'
import {
  PopupContainer,
  Heading,
  SubHeading,
  Text,
  Recommended,
  ShopPayImage,
  CtaContainer,
  Disclosure,
} from './styles'

import AddToCartButton from '../../AddToCartButton'

const ToolTip = ({
  selectedCalendar,
  setDisplayToolTip,
  displayAddToCart,
  size,
  style,
  color,
  amount,
}) => {
  const { closeIcon, shopPayInstallmentsLogo } = useStaticQuery(graphql`
    query ToolTipQuery {
      closeIcon: file(relativePath: { eq: "icons/close-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 28, layout: FIXED, placeholder: NONE)
        }
      }
      shopPayInstallmentsLogo: file(relativePath: { eq: "calmax/shop-pay-installments-logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const [submitting, setSubmitting] = useState(false)

  const { addToCart } = useContext(StoreContext)

  const handleAddToCart = async (variantId, quantity) => {
    const id = variantId.split('/').pop()
    const pushToDataLayer = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                id,
                name: selectedCalendar.name,
                category: 'Calendar',
                price: selectedCalendar.price,
                quantity,
              },
            ],
          },
        },
      })
    }

    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Calendar to Cart`)
      Analytics.track(`Added Tooltip Calendar to Cart`)
      const baseEvent = `Added ${size}in Cal`
      const message =
        size === 10 || size === 15
          ? `${baseEvent} to Cart`
          : `${baseEvent} ${style} ${color} to Cart`
      Analytics.track(message)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        setDisplayToolTip(false)
        pushToDataLayer(variantId, 1)
        setSubmitting(false)
        navigate('/cart/')
      }
    }
  }
  const splitAmount =
    amount ??
    (SPLIT_PRICE[size]?.[style] !== undefined
      ? `$${SPLIT_PRICE[size][style]}`
      : `$${SPLIT_PRICE[size]?.default}`)
  return (
    <PopupOverlay>
      <PopupContainer>
        <CloseButton onClick={() => setDisplayToolTip(false)}>
          <GatsbyImage image={closeIcon.childImageSharp.gatsbyImageData} alt="Close" />
        </CloseButton>
        <Heading>Buy now, pay later — choose the way you pay.</Heading>
        <SubHeading>
          Choose Shop Pay at checkout to pay in full or to split your purchase into 4 equal
          installments of <b>{splitAmount}</b>.*
        </SubHeading>
        {BNPL_FEATURES.map((feature, index) => (
          <div key={index}>
            <Recommended>{feature.title}</Recommended>
            <Text>{feature.text}</Text>
          </div>
        ))}
        <CtaContainer>
          {displayAddToCart && (
            <AddToCartButton
              label="Add to Cart"
              handleClick={() => handleAddToCart(selectedCalendar.shopifyId, 1)}
              oos={false}
              adding={submitting}
            />
          )}
          <AddToCartButton label="Close" handleClick={() => setDisplayToolTip(false)} oos={false} />
        </CtaContainer>
        <ShopPayImage>
          <GatsbyImage
            image={shopPayInstallmentsLogo.childImageSharp.gatsbyImageData}
            alt="Shop Pay Installments partnership with affirm logo"
          />
        </ShopPayImage>
        <Disclosure>
          *Rates from 0-36% APR. Payment options through Shop Pay Installments are subject to an
          eligibility check and are provided by these lending partners:{' '}
          <a href="https://affirm.com/lenders" target="_blank" rel="noreferrer">
            [affirm.com/lenders]
          </a>
          . Options depend on your purchase amount, and a down payment may be required. State
          notices to consumers{' '}
          <a href="https://www.affirm.com/licenses" target="_blank" rel="noreferrer">
            [affirm.com/licenses]
          </a>
          .
        </Disclosure>
      </PopupContainer>
    </PopupOverlay>
  )
}

export default ToolTip

ToolTip.propTypes = {
  selectedCalendar: PropTypes.object,
  setDisplayToolTip: PropTypes.func.isRequired,
  displayAddToCart: PropTypes.bool.isRequired,
  size: PropTypes.number,
  style: PropTypes.string,
  color: PropTypes.string,
  amount: PropTypes.number,
}
