import { IS_FOREIGN_COUNTRY } from './constants'

export const FramePriceByColor = (selectedColor, frameOptions) => {
  const frame = frameOptions[selectedColor] || frameOptions.Black
  return frame.data.shopifyProduct.variants[0].price
}

export const DefaultFrameConfig = (handle, defaultConfig) => {
  if (IS_FOREIGN_COUNTRY) {
    if (handle === 'the-skylight-frame-15') {
      return { color: 'Black', size: '15', style: 'Classic' }
    }
    return { color: 'Black', size: '10', style: 'Classic' }
  }
  const handleConfigMap = {
    'the-skylight-frame-black': { color: 'Black', size: '10', style: 'Classic' },
    'the-skylight-frame-15': { color: 'Black', size: '15', style: 'Classic' },
    'the-skylight-frame-white': { color: 'White', size: '10', style: 'Classic' },
    'the-skylight-frame-silver': { color: 'Silver', size: '10', style: 'Classic' },
    'the-skylight-frame-gold': { color: 'Gold', size: '10', style: 'Classic' },
    'the-skylight-frame-poppy': { color: 'Poppy', size: '10', style: 'Classic' },
    'skylight-frame-2-classic-black': { color: 'Black', size: '10F2', style: 'Classic' },
    'skylight-frame-2-classic-black-with-plus': { color: 'Black', size: '10F2', style: 'Classic' },
    'skylight-frame-2-classic-white': { color: 'White', size: '10F2', style: 'Classic' },
    'skylight-frame-2-classic-white-with-plus': { color: 'White', size: '10F2', style: 'Classic' },
    'skylight-frame-2-classic-lagoon': { color: 'Lagoon', size: '10F2', style: 'Classic' },
    'skylight-frame-2-classic-lagoon-with-plus': {
      color: 'Lagoon',
      size: '10F2',
      style: 'Classic',
    },
    'skylight-frame-2-classic-sage': { color: 'Sage', size: '10F2', style: 'Classic' },
    'skylight-frame-2-classic-sage-with-plus': { color: 'Sage', size: '10F2', style: 'Classic' },
    'skylight-frame-2-wood-walnut': { color: 'Walnut', size: '10F2', style: 'Wood' },
    'skylight-frame-2-wood-walnut-with-plus': { color: 'Walnut', size: '10F2', style: 'Wood' },
    'skylight-frame-2-wood-birch': { color: 'Birch', size: '10F2', style: 'Wood' },
    'skylight-frame-2-wood-birch-with-plus': { color: 'Birch', size: '10F2', style: 'Wood' },
    'skylight-frame-2-shadow-box-charcoal': {
      color: 'Charcoal',
      size: '10F2',
      style: 'Shadow Box',
    },
    'skylight-frame-2-shadow-box-charcoal-with-plus': {
      color: 'Charcoal',
      size: '10F2',
      style: 'Shadow Box',
    },
    'skylight-frame-2-shadow-box-aluminum': {
      color: 'Aluminum',
      size: '10F2',
      style: 'Shadow Box',
    },
    'skylight-frame-2-shadow-box-aluminum-with-plus': {
      color: 'Aluminum',
      size: '10F2',
      style: 'Shadow Box',
    },
    'skylight-frame-2-cover-black': { color: 'Black', size: '10F2', style: 'Cover' },
    'skylight-frame-2-cover-black-with-plus': { color: 'Black', size: '10F2', style: 'Cover' },
    'skylight-frame-2-cover-white': { color: 'White', size: '10F2', style: 'Cover' },
    'skylight-frame-2-cover-white-with-plus': { color: 'White', size: '10F2', style: 'Cover' },
  }

  return handleConfigMap[handle] || defaultConfig
}

export function GetFrameDiscountDetails(framePrices, lang, selectedColor, isColor) {
  const origPriceKey = isColor
    ? `${lang}_original_price_${selectedColor}`
    : `${lang}_original_price`
  const discPriceKey = isColor
    ? `${lang}_discounted_price_${selectedColor}`
    : `${lang}_discounted_price`
  const origPrice = framePrices[origPriceKey]
  const discPrice = framePrices[discPriceKey]
  const isDiscounted = origPrice !== 'NONE' && discPrice !== 'NONE' && origPrice !== discPrice
  const discBubbleCopy = isColor
    ? framePrices[`${lang}_discount_bubble_${selectedColor}`]
    : framePrices[`${lang}_discount_bubble`]

  return { isDiscounted, origPrice, discPrice, discBubbleCopy }
}
export function GetCtaDetails(lang, isCal, size, contentfulCTAOverrides, selectedColor) {
  const productType = isCal ? 'calendar' : 'frame'

  let CTA_KEY

  if (size === 27) {
    let productColor = selectedColor.toLowerCase()
    if (productColor === 'natural aluminum') {
      productColor = 'aluminum'
    }
    CTA_KEY = `${lang}_${size}_${productColor}_${productType}`
  } else CTA_KEY = `${lang}_${size}_${productType}`
  if (lang === 'en' && !isCal) {
    CTA_KEY += `_${selectedColor}`
  }

  const CTA_VALUE = contentfulCTAOverrides[CTA_KEY]
  const isOutOfStock = CTA_VALUE === 'Out of Stock' || CTA_VALUE === 'Sold Out'
  const isPreorder = CTA_VALUE === 'Preorder'

  return {
    CTA_VALUE,
    isOutOfStock,
    isPreorder,
  }
}

export const DefaultCalendarSelection = (handle, default27CalendarSelection) => {
  switch (handle) {
    case 'skylight-calendar-max-classic-black':
      return { size: 27, color: 'Black', style: 'Classic' }
    case 'skylight-calendar-max-shadow-box-charcoal':
      return { size: 27, color: 'Charcoal', style: 'Shadow Box' }
    case 'skylight-calendar-max-shadow-box-natural-aluminum':
      return { size: 27, color: 'Natural Aluminum', style: 'Shadow Box' }
    case 'skylight-calendar-max-classic-white':
      return { size: 27, color: 'White', style: 'Classic' }
    case 'skylight-calndar-max':
      return { size: 27, color: 'Natural Aluminum', style: 'Shadow Box' }
    default:
      return default27CalendarSelection
  }
}
