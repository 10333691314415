import styled from 'styled-components'

export const OuterCircle = styled.div`
  margin-top: ${(props) => (props.bogo ? '20px' : '0px')};
  width: ${(props) => (props.smallRadio ? '10px' : '56px')};
  height: ${(props) => (props.smallRadio ? '10px' : '56px')};
  border-radius: 50%;
  position: relative;
  z-index: 1;
  border: ${(props) =>
    props.selected ? '3px solid rgba(33, 120, 175, 1)' : '1px solid rgba(218, 218, 218, 1)'};
  opacity: 1;
  box-sizing: border-box;
  .Black {
    background: rgba(46, 46, 46, 1);
  }
  .Silver {
    background: linear-gradient(180deg, #f8f8f8 0%, #acafae 100%);
  }
  .White {
    background: rgba(255, 255, 255, 1);
  }
  .Gold {
    background: linear-gradient(180deg, #ffe37e 0%, #a8913f 100%);
  }
  .Poppy {
    background: linear-gradient(180deg, #ff6855 0%, #c71500 100%);
  }
  .Aluminum {
    background: linear-gradient(139deg, #e9e7e7 21.02%, #b1b0b0 66.56%);
  }
  .Charcoal {
    background: linear-gradient(145deg, #595959 25.12%, #2e2e2e 77.36%);
  }
  .Sage {
    background: linear-gradient(139deg, #b0baa4 20%, #a0ad94 50%, #8a9478 80%);
  }
  .Lagoon {
    background: #93acb7;
  }
  .Walnut {
    background: linear-gradient(145deg, #b67b3b 25.12%, #895721 77.36%);
  }
  .Birch {
    background: linear-gradient(139deg, #f5c97f 21.02%, #cfa55d 66.56%);
  }
  @media (max-width: 420px) {
    width: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '50px' : '56px')};
    height: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '50px' : '56px')};
  }
  @media (max-width: 350px) {
    width: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '44px' : '56px')};
    height: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '44px' : '56px')};
  }
`
export const Circle = styled.div`
  width: ${(props) => (props.smallRadio ? '10px' : '40px')};
  height: ${(props) => (props.smallRadio ? '10px' : '40px')};
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
  opacity: ${(props) => (props.oos ? '40%' : '100%')};
  box-sizing: border-box;
  @media (max-width: 420px) {
    width: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '36px' : '40px')};
    height: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '36px' : '40px')};
  }
  @media (max-width: 350px) {
    width: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '31px' : '40px')};
    height: ${(props) => (props.smallRadio ? '10px' : props.mediumRadio ? '31px' : '40px')};
  }
`

export const DiagonalLine = styled.div`
  width: 100%;
  height: 3px;
  background: rgba(68, 68, 68, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%) rotate(135deg);
`
export const Bogo = styled.div`
  background-color: ${(props) => props.theme.blueDark};
  position: absolute;
  z-index: 5;
  top: ${(props) => (props.selected ? '-22px' : '-20px')};
  left: ${(props) => (props.selected ? '23px' : '25px')};
  border-radius: 25px;
  border: 1px solid ${(props) => props.theme.blueLight};
  padding-bottom: 2px;
  span {
    font-family: 'filsonProBook';
    line-height: normal;
    font-size: 12px;
    padding: 0px 7px;
    margin: 0px;
    color: ${(props) => props.theme.blueLight};
  }
`
