import React from 'react'
import PropTypes from 'prop-types'
import { Text, ColorSelection, ColorSelectionContainer } from '../FrameProductForm/styles'
import Radio from '../Radio'

const ColorSelectionItem = ({
  variant,
  onSelectColor,
  isOutOfStock,
  isSelected,
  smallRadio,
  mediumRadio,
  bogo,
}) => (
  <ColorSelectionContainer>
    <ColorSelection
      smallRadio={smallRadio}
      mediumRadio={mediumRadio}
      onClick={() => {
        if (!smallRadio) {
          onSelectColor(variant)
        }
      }}
    >
      <Radio
        color={variant}
        oos={isOutOfStock}
        selected={isSelected}
        smallRadio={smallRadio}
        mediumRadio={mediumRadio}
      />
      {!smallRadio && !bogo && <Text>{variant}</Text>}
    </ColorSelection>
  </ColorSelectionContainer>
)

ColorSelectionItem.propTypes = {
  variant: PropTypes.string.isRequired,
  onSelectColor: PropTypes.func.isRequired,
  isOutOfStock: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  smallRadio: PropTypes.bool,
  mediumRadio: PropTypes.bool,
  bogo: PropTypes.bool,
}

export default ColorSelectionItem
