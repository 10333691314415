export const BNPL_FEATURES = [
  {
    title: 'Easy payments',
    text: 'Split your purchase into easy bi-weekly or monthly payments.',
  },
  {
    title: 'No surprises',
    text: 'No late or hidden fees.',
  },
  {
    title: 'Quick decision',
    text: 'Get a real-time decision on your eligibility.',
  },
]
